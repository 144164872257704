import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PortfolioItemSummary from "../../components/PortfolioItemSummary"

const PortfolioG1G = () => {
  return (
    <Layout bgClass="bg-g1g-darkblue" fgMode="light">
      <Seo 
        title="G1 Goldmine"
        description="The Leading Strategic Pedigree System for Thoroughbred Breeders"
      />
      <section className="mx-auto text-white bg-g1g-darkblue">
        <div className="flex flex-col items-center mx-4 lg:max-w-screen-lg lg:mx-auto">
          <StaticImage 
            height={80}            
            alt="Logo - G1 Goldmine"
            src="../../images/logo-g1g-lg.png" 
            placeholder="blurred"/>
          <h1 className="hidden">G1 Goldmine</h1>
          <p className="pt-10 text-3xl text-center">Looks like G1 Goldmine. Feels like Stripe.</p>
          <p className="pt-4 text-xl text-center">
            We collaborated with G1's software team to bring a 100% custom Stripe experience.
            <br/>
            Using Elements, Subscriptions and Billing.
          </p>
        </div>
        <PortfolioItemSummary 
            expertise="Design, Team augmentation" 
            platforms="Internet" 
            deliverables="API, backend infrastructure" 
            href="https://www.g1goldmine.com" 
            linkText="G1 Goldmine" />
      </section>
      <div className="hidden lg:grid grid-cols-[1fr,800px,1fr] grid-rows-[repeat(7,1fr)]">
        <div className="flex col-start-1 col-end-4 row-start-1 row-end-3 bg-g1g-darkblue">
        </div>
        <div className="col-start-2 col-end-3 row-start-1 row-end-7">
          <div className="shadow-2xl">
            <StaticImage 
              alt="G1G Preview"
              src="../../images/preview-g1g.png" 
              placeholder="blurred"/>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-10 text-gray-700 lg:mt-0 lg:mx-auto lg:max-w-screen-md">
        <p className="mb-6 text-2xl">
          G1 Goldmine is the leading strategic pedigree system for thoroughbred breeders 
          with over 9,000 members, which includes some of the world’s elite breeders.
        </p>
        <p className="mb-6 text-2xl">
          G1 Goldmine is now a global brand within the pedigree analysis sector. 
          In a typical week, members from 12 different countries perform over 4,000 Stallion Matches 
          (matching their mares against stallions) as well as over 1,200 Pedigree 
          Searches (searching for successful pedigree patterns).
        </p>
      </div>

      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-g1g-darkblue my-14">Integrating Stripe's smarts</h2>
        <p className="mb-6 text-xl">
          G1 Goldmine found us through Stripe’s networks. As part of their recent rebuild (launched late 2020) they 
          wanted to automate billing and subscriptions, and who better than Stripe to solve such complex payment problems.
        </p>
        <p className="mb-6 text-xl">
          Their team reached out to us and we got working on best practices to integrate Stripe deeply into their system. 
          G1 Goldmine was after a completely custom experience. Our team was able to augment their developers to integrate Stripe and 
          bring and go live with as  little pain as possible.
        </p>
        <p className="mb-6 text-xl">
          Through the journey we were able to assist with other Microsoft web technologies like Identity server to keep expanding their platform.
        </p>
        <p className="mb-6 text-xl">
          G1 Goldmine are a prized Stripe customer with a continuing working relationship with Anomaly.
        </p>
      </div>

      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-g1g-darkblue my-14">Who we worked with</h2>
        <p className="mb-6 text-xl">
          Stripe is an industry leader in payments. Anomaly is proud to be one of their first expert services partners. 
          Our partnership with Stripe has allowed us to advise, and help integrate their products into ambitious SaaS products.
        </p>
        <p className="mb-6 text-xl">
          We pride ourselves on deeply understanding their enormous product offerings and provide best practices ways 
          for businesses to utilise their capabilities.
        </p>
      </div>

    </Layout>
 );
};
export default PortfolioG1G
